export const techSkills = [
  "HTML",
  "CSS",
  "JavaScript",
  "ReactJs",
  "Redux",
  "NodeJs",
  "Bootstrap",
  "Tailwind",
  "Vite",
  "Sass",
];
